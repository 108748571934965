const blogauthors = [
  {
    name: 'Kagwe Muchane',
    bio: 'Kagwe Muchane is founder of Kagwave.',
    links: {
      twitter: 'https://twitter.com/kagwave',
      instagram: 'https://instagram.com/kagwave',
      linkedin: 'http://linkedin.com/in/kagwemuchane'
    }
  }
]

export default blogauthors;
