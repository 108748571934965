import './Store.css';
import { useEffect } from 'react';

import MountDisplay from '../../interface/tools/MountDisplay';

const Store = () => {

  useEffect(() => {
    MountDisplay(undefined, "Store");
  }, []);

  return (  
    <div id="page-content">
      <div className="inProgress fade-in">
        <div className="inProgressText"> launching soon... </div>
      </div>
    </div>
  );
}
 
export default Store;